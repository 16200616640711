<template>
  <section class="not-found-section">
    <h4>{{ $t('message.forbidden_title') }}</h4>
  </section>
</template>

<script>
export default {
  name: 'Forbidden',
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
