var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "not-found-section" }, [
    _c("h4", [_vm._v(_vm._s(_vm.$t("message.forbidden_title")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }